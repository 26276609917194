import { GET_BLOGS, GET_BLOGS_BY_ID, GET_BLOGS_CATEGORY } from "Redux/Actions/ActionTypes"
import { startLoader, stopLoader } from "Redux/Actions/Loader";
import { API_URLS, STATUS_CODES } from "Services/Api/Constants";
import { getRequestNoAuthLuxe } from "Shared/Axios";
import { all, put, takeLatest } from "redux-saga/effects"

function* getBlogsRequest({payload}) {
    try {
		yield put(startLoader())
		const {data, status} = yield getRequestNoAuthLuxe({
			API: API_URLS.GET_GIVE2_THE_KIDS_BLOGS+`?limit=${payload?.limit}&skip=${payload?.skip}${payload?.searchKey? `&searchKey=${payload?.searchKey}`: ""}${payload?.blogCategoryId ? `&blogCategoryId=${payload?.blogCategoryId}`: ""}` ,
		});
	
		if(status===STATUS_CODES.SUCCESS){
			console.log(data)
			if (payload?.success) {
			payload?.success(data?.data)
			}
		}
    } catch (error) {
		console.log(error);
		if (payload?.fail) {
			payload?.fail(error?.data?.message || error?.response?.data?.message);
		}
    }
    finally{
      	yield put(stopLoader())
    }
}

function* getBlogsByIdRequest({payload}) {
    try {
		yield put(startLoader())
		const {data, status} = yield getRequestNoAuthLuxe({
			API: API_URLS.GET_GIVE2_THE_KIDS_BLOGS+`?blogId=${payload?.blogId}` ,
		});
	
		if(status===STATUS_CODES.SUCCESS){
			console.log(data)
			if (payload?.success) {
				payload?.success(data?.data)
			}
		}
    } catch (error) {
		console.log(error);
		if (payload?.fail) {
			payload?.fail(error?.data?.message || error?.response?.data?.message);
		}
    }
    finally{
      	yield put(stopLoader())
    }
}

function* getBlogCategoryRequest({payload}) {
    try {
		yield put(startLoader())
		const {data, status} = yield getRequestNoAuthLuxe({
			API: API_URLS.BLOGS_CATEGORY+`?limit=${payload?.limit}&skip=${payload?.skip}` ,
		});
	
		if(status===STATUS_CODES.SUCCESS){
			console.log(data)
			if (payload?.success) {
				payload?.success(data?.data)
			}
		}
    } catch (error) {
		console.log(error);
		if (payload?.fail) {
			payload?.fail(error?.data?.message || error?.response?.data?.message);
		}
    }
    finally{
      	yield put(stopLoader())
    }
}

function* watchBlogs() {
    yield all([
		takeLatest(GET_BLOGS_CATEGORY, getBlogCategoryRequest),
		takeLatest(GET_BLOGS_BY_ID, getBlogsByIdRequest),
		takeLatest(GET_BLOGS, getBlogsRequest),
    ])
}

export default watchBlogs